import { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { getTranslation } from "wes_shell_app/localization-utils";

export const columns: IDataTableHeaderCellProps[] = [
  { label: "line", sortKey: "line" },
  { label: "pickOrder", info: "pickOrderInfo" },
  { label: "pickAddress", info: "pickAddressInfo" },
  { label: "area" },
  { label: "aisle" },
  { label: "bay" },
  { label: "level" },
  { label: "cell" },
  { label: "checkDigit", info: "checkDigitInfo" },
  { label: "status" },
  { label: "itemId", info: "itemIdInfo" },
  { label: "itemLogistics", info: "itemLogisticsInfo" },
  { label: "itemName" },
  { label: "weightFlag", info: "weightFlagInfo" },
  { label: "avgWeight", info: "avgWeightInfo" },
  { label: "minWeightLimit", info: "minWeightLimitInfo" },
  { label: "maxWeightLimit", info: "maxWeightLimitInfo" },
  { label: "pickQtyPc", info: "pickQtyPcInfo" },
  { label: "pickQtyPu", info: "pickQtyPuInfo" },
  { label: "pickUnit", info: "pickUnitInfo" },
  { label: "pickedQtyPc", info: "pickedQtyPcInfo" },
  { label: "availableQtyPc", info: "availableQtyPcInfo" },
  { label: "pcInPu", info: "pcInPuInfo" },
  { label: "pcInSubcart", info: "pcInSubcartInfo" },
  { label: "subcartInCart", info: "subcartInCartInfo" },
  { label: "autoPickId", info: "autoPickIdInfo" },
  { label: "comment" },
];

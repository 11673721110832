/**
 * Mapps data comming from server API to client models
 */

import * as serverModels from "./server-models";
import * as clientModels from "./client-models";
import formatters from "wes_shell_app/localization-utils";

export const serverToClientHeaderInfoModel = (
  item: serverModels.IWebSocketHeaderInfoModel
): clientModels.IClientHeaderInfoModel => ({
  ...item,
  itemsFullnessArray: [item.sortedItems, item.totalItems],
  ordersFullnessArray: [item.sortedOrders, item.totalOrders],
  bufferFullnessArray: [item.bufferItems],
  hospitalFullnessArray: [item.hospitalItems],
  headerInfoStartedDate: item.started
    ? formatters.formatDate(item.started)
    : "-",
  waveID: item.id ? item.id : "-",
});

export const serverToClientRackModel = (
  item: serverModels.IServerRackModel
): clientModels.IClientRackModel => ({
  ...item,
  shelfType: item.mobility ? "mobile" : "static",
  shelves: item.shelves.map((shelf, index) => ({
    ...shelf,
    shelfId: index + 1,
  })),
});

export const serverToClientTerminalModel = (
  item
): clientModels.IAPIClientTerminalModel => {
  return {
    ...item,
    registered: formatters.formatDateTime(item.registered),
    unregistered: formatters.formatDateTime(item.unregistered),
  };
};

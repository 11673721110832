import { observer } from "mobx-react";
import { useItemsViewStore } from "../hooks/use-items-store";
import { Box } from "@mui/material";
import TextInputField from "wes_shell_app/text-input-field";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { ItemsStatus } from "../utils/filter-utils";

export const ItemsDataTableFilter = observer(() => {
  const store = useItemsViewStore();

  return (
    <>
      <Box className="flex flex-row justify-between space-x-2 w-full mt-5 mb-6">
        <Box className="flex flex-row gap-2">
          <Box className="w-48">
            <FilterSelector
              intld={"status"}
              placeholder={"status"}
              value={store.getFilterValue("status")}
              items={ItemsStatus}
              onChange={(v) => store.setFilter("status", v)}
              onClear={() => store.clearFilter("status")}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("itemId")}
              placeholder="itemId"
              label="itemIdInfo"
              onChange={(v) => store.setFilter("itemId", v)}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("itemName")}
              placeholder="itemName"
              label="itemName"
              onChange={(v) => store.setFilter("itemName", v)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

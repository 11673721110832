import { SelectItemType } from "wes_shell_app/types";

export type IOrdersFilterParams =
  | "status"
  | "batchId"
  | "lot"
  | "customer"
  | "time"
  | "timeFrom"
  | "timeTo";

export type IItemsFilterParams = "id" | "status" | "itemName" | "itemId";

export const Status: SelectItemType[] = [
  {
    contentIntl: "NEW",
    id: "NEW",
  },
  {
    contentIntl: "STARTED",
    id: "STARTED",
  },
  {
    contentIntl: "INPICKING",
    id: "INPICKING",
  },
  {
    contentIntl: "ONHOLD",
    id: "ONHOLD",
  },
  {
    contentIntl: "FINISHED",
    id: "FINISHED",
  },
];

export const ItemsStatus: SelectItemType[] = [
  {
    contentIntl: "NEW",
    id: "NEW",
  },
  {
    contentIntl: "PARTPICKED",
    id: "PARTPICKED",
  },
  {
    contentIntl: "PICKED",
    id: "PICKED",
  },
];

import ModelDialog, { IWithModalDialog } from "wes_shell_app/model-dialog";
import { IAPIPrinterClientModel } from "models/client-models";
import { PrinterAddForm } from "./printer-add-dialog-form";
import { usePrintersStore } from "../hooks/use-printers-store";
import { PrintSingleDataProvider } from "../stores/data/printers-single-data-provider";

type IPrintDialogFormProps = IWithModalDialog<IAPIPrinterClientModel>;

export const PrinterAddDialogForm = (props: IPrintDialogFormProps) => {
  const store = usePrintersStore();

  return (
    <>
      <ModelDialog
        intlId="addPrinter"
        onSuccess={store.forceReload}
        dialogTitleIntl="newPrint"
        type={props.type}
        defaultValue={props.defaultValue}
        provider={new PrintSingleDataProvider()}
      >
        <PrinterAddForm />
      </ModelDialog>
    </>
  );
};

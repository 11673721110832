import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { OrdersDataTable } from "../components/orders-data-table";
import { PrintersDataTableFilter } from "../components/orders-data-table-filters";
import { ItemsDrawer } from "../components/items-drawer";

const OrdersView = observer(() => {
  return (
    <BaseView>
      <PrintersDataTableFilter />
      <OrdersDataTable />
      <ItemsDrawer />
    </BaseView>
  );
});

export default OrdersView;

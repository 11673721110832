import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import SharedContextStore from "wes_shell_app/shared-context-store";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class DashboardViewStore {
  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  constructor() {
    makeAutoObservable(this);
  }
}

import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { usePrintersStore } from "../hooks/use-printers-store";
import { Delete, Edit } from "@mui/icons-material";
import { IAPIPrinterClientModel } from "models/client-models";
import { PrintersViewStore } from "../stores/view/printers-view-store";
import Button from "wes_shell_app/button";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import ConfirmDialog from "wes_shell_app/confirm-dialog";
import { useEffect, useState } from "react";
import { PrinterEditDialogForm } from "./printer-edit-dialog";
import { getTranslation } from "wes_shell_app/localization-utils";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
    sortKey: "id",
  },
  {
    label: "printerCode",
  },
  {
    label: "name",
    sortKey: "name",
  },
  {
    label: "Model",
  },

  {
    label: "description",
  },

  {
    label: "type",
  },
  {
    label: "ipAddress",
  },
  {
    label: "btName",
  },

  {
    label: "protocol",
  },
];

export const PrintersDataTable = observer(() => {
  const store = usePrintersStore();

  const [showDialog, setshowDialog] = useState<boolean>(false);
  const handleSelectPrinter = (item: IAPIPrinterClientModel) => {
    store.setSelectedPrinter(item);
    setshowDialog(true);
  };

  //after confirmAction
  const sendData = () => {
    store.updateDataAPI(store.selectedPrinter);
    store.forceReload();
    setshowDialog(false);
  };

  useEffect(() => {
    store.getModelAPI();
  }, []);

  if (!store.isLoaded) return null;
  const confirmDialog = useConfirmDialog();
  return (
    <>
      <PrinterEditDialogForm
        showDialog={showDialog}
        onClose={() => setshowDialog(false)}
        confirmAction={() => sendData()}
      />
      <DataTable
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            confirmDialog={confirmDialog}
            handleSelectPrinter={handleSelectPrinter}
          />
        )}
      />
      <ConfirmDialog
        onConfirm={() => store.deletePrinter(confirmDialog.item)}
      />
    </>
  );
});

export type IRowTemplateProps = {
  item: IAPIPrinterClientModel;
  store: PrintersViewStore;
  confirmDialog: { open: (item) => void };
  handleSelectPrinter: (item: IAPIPrinterClientModel) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const {
    id,
    printerCode,
    ipAddress,
    type,
    name,
    description,
    protocol,
    model,
    btName,
  } = props.item;

  const { handleSelectPrinter } = props;

  return (
    <>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{printerCode}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{model}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{getTranslation(type)}</TableCell>
        <TableCell>{ipAddress}</TableCell>
        <TableCell>{btName}</TableCell>
        <TableCell>{protocol}</TableCell>
        <TableCell>
          {props.store.isAdmin && (
            <Button
              variant="icon"
              icon={<Edit color="primary" />}
              onClick={() => {
                handleSelectPrinter(props.item);
              }}
            />
          )}
        </TableCell>
        <TableCell>
          {props.store.isAdmin && (
            <Button
              variant="icon"
              icon={<Delete color="error" />}
              onClick={() => props.confirmDialog.open(props.item)}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
});

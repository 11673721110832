/**
 * Models of all data comming from server via API.
 * Type declarations according to Swagger.
 */

import { IWithId } from "wes_shell_app/api-types";

export type IWebSocketHeaderInfoModel = {
  id: number;
  sortedItems: number;
  totalItems: number;
  sortedOrders: number;
  totalOrders: number;
  bufferItems: number;
  hospitalItems: number;
  duration: number;
  name: string;
  started: Date;
  state: string;
};

export enum IAPIPrinterType {
  NETWORK = "network",
  PORTABLEBT = "portableBT",
}

export type IAPIPrinterModelsProtocols = {
  model: string[];
  protocol: string[];
};

export type IAPIPrinterModel = {
  id: number;
  printerCode: string;
  ipAddress?: string;
  type: IAPIPrinterType;
  name: string;
  description?: string;
  stationId: number;
  protocol: string;
  created: Date;
  updated: Date;
  model: string;
  btName: string | null;
};

export type IRackModel = {
  active?: boolean;
  name: string;
  stationId?: number;
  description: string;
  length: number;
  height?: number;
  depth: number;
  type: string;
  mobility: boolean;
  zeroGround: boolean;
  numberingRack: boolean;
  rackStartsWith: string;
  numberingShelf: boolean;
  shelfStartsWith: string;
  shelves: IServerCreateRackShelfModel[];
};

export type IServerCreateRackModel = IWithId & IRackModel;

export type IServerCreateRackShelfModel = IWithId & {
  shelfId: number;
  name: string;
  rackId: number;
  level: number;
  positions: number;
  height: number;
};

export type IServerRackModel = IWithId & IRackModel;

export type IAPITerminalModel = {
  id: number;
  firstname: string;
  surname: string;
  cardId: number;
  process: string;
  terminalId: string;
  ipAddress: string;
  registered: Date;
  unregistered: Date;
};

export type IOrdersModel = {
  id: number;
  batchId: number;
  lot: number;
  site: string;
  depot: string;
  workplace: {
    id: number;
    name: string;
  };
  erpZone: string;
  status: string;
  supportType: string;
  supportCode: string;
  supportCount: number;
  customer: string;
  expDockAddress: string;
  checkDigit: string;
  lotType: string;
  qtySum: number;
  lotOrder: number;
  itemsCount: number;
  issued: string;
  zoneName: string;
  preparedTo: string;
  lotStartDate: string | null;
  lotEndDate: string | null;
  priority: number;
};

export type IItemsModel = {
  id: number;
  line: number;
  pickOrder: number;
  pickAddress: string;
  area: string;
  aisle: string;
  bay: string;
  level: string;
  cell: string;
  checkDigit: string;
  status: string;
  itemId: string;
  itemLogistics: string;
  itemName: string;
  weightFlag: string;
  avgWeight: number;
  minWeightLimit: number;
  maxWeightLimit: number;
  pickQtyPc: number;
  pickQtyPu: number;
  pickUnit: string;
  pickedQtyPc: number;
  availableQtyPc: number;
  pcInPu: number;
  pcInSubcart: number;
  subcartInCart: number;
  autoPickId: string;
  comment: string;
};

import { environment } from "environment";
import {} from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import WarehouseMapDataStore from "wes_shell_app/warehouse-map-data-store";
import { TWarehouseMapRackModel } from "wes_shell_app/warehouse-map-utils";

export class WarehousMapRackDataProvider extends WarehouseMapDataStore<TWarehouseMapRackModel> {
  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return `ui/map/rack`;
  }

  get endpointUrl(): string {
    return `${this.url}`;
  }
}

import { environment } from "environment";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import {
  TWarehouseMapDataTableRow,
  TWarehouseMapFilterParams,
} from "wes_shell_app/warehouse-map-utils";

export class WarehousMapDataTableProvider extends DataTableStore<
  TWarehouseMapDataTableRow,
  TWarehouseMapFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return `ui/map/aisle`;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.sharedContextStore.appContext.currentStationId}`;
  }
}

import { observer } from "mobx-react";
import { useOrdersViewStore } from "../hooks/use-orders-store";
import { Box } from "@mui/material";
import TextInputField from "wes_shell_app/text-input-field";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { Status } from "../utils/filter-utils";
import DateTimePicker from "wes_shell_app/date-time-picker";

export const PrintersDataTableFilter = observer(() => {
  const store = useOrdersViewStore();

  return (
    <>
      <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-6">
        <Box className="flex flex-row gap-2">
          <Box className="flex flex-row justify-between space-x-2">
            <DateTimePicker
              fullWidth
              intlId="timeFrom"
              value={store.getFilterValue("timeFrom")}
              onConfirm={(v) => store.setDateFilterValue("timeFrom", v)}
              defaultValue={store.defaultTimeFromValue}
            />
            <DateTimePicker
              fullWidth
              intlId="toDate"
              value={store.getFilterValue("timeTo")}
              onConfirm={(v) => store.setDateFilterValue("timeTo", v)}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("batchId")}
              placeholder="batchId"
              label="batchId"
              onChange={(v) => store.setFilter("batchId", v)}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("lot")}
              placeholder="lot"
              label="lot"
              onChange={(v) => store.setFilter("lot", v)}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <FilterSelector
              intld={"status"}
              placeholder={"status"}
              value={store.getFilterValue("status")}
              items={Status}
              onChange={(v) => store.setFilter("status", v)}
              onClear={() => store.clearFilter("status")}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("customer")}
              placeholder="customer"
              label="customer"
              onChange={(v) => store.setFilter("customer", v)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

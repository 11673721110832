import { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { getTranslation } from "wes_shell_app/localization-utils";

export const columns: IDataTableHeaderCellProps[] = [
  { label: "priority" },
  { label: "created" /*sortKey: "created"*/ },
  { label: "batchId" /*sortKey: "batchId"*/ },
  { label: "lot" /*sortKey: "lot"*/ },
  { label: "zone" },
  { label: "erpZone", info: "zoneErpInfo" },
  { label: "status" /*sortKey: "status" */ },
  { label: "supportCount", info: "supportCountInfo" },
  { label: "supportCode", info: "supportCodeInfo" },
  { label: "customer" },
  { label: "expDockAddress", info: "expDockAddressInfo" },
  { label: "checkDigit", info: "checkDigitInfo" },
  { label: "lotType", info: "lotTypeInfo" },
  { label: "qtySum" },
  { label: "lotOrder", /* sortKey: "lotOrder",*/ info: "lotOrderInfo" },
  { label: "itemsCount", info: "itemsCountInfo" },
  { label: "preparedTo" },
  { label: "lotStartDate" },
  { label: "lotEndDate" },
];

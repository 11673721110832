import { environment } from "environment";
import { IServerCreateRackModel } from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";

const store = new SharedContextStore();
const currentStationId = store.appContext.currentStationId;

export class RackRequestDataProvider extends SingleDataStoreProvider<IServerCreateRackModel> {

    get endpointUrl(): string { return `ui/rack/${currentStationId}`; }
    protected serviceApiUrl = environment.serviceApi;

    get defaultModel() {
        return {
            stationId: currentStationId,
        }
    }

}
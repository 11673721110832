import Button from "wes_shell_app/button";
import RackShelvNew from "./dialogs/shelves/rack-shelv-new";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import RackShelvClone from "./dialogs/shelves/rack-shelv-clone";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { useRackStore } from "../hooks/use-rack-store";

const RackShelvesHeader = observer(() => {
  const store = useShelvesStore();
  const rackStore = useRackStore();

  const saveHandler = async () => {
    const response: boolean = await store.editRackAPI();
    

    if (!response) return;

    store.closeDrawer();
    rackStore.forceReload();
  };

  return (
    <Box className={"flex gap-2 justify-end mt-4"}>
      <RackShelvClone />
      <RackShelvNew />
      <Button
        variant={"contained"}
        intlId={"save"}
        color="success"
        onClick={saveHandler}
      />
    </Box>
  );
});

export default RackShelvesHeader;

import { makeObservable, observable } from "mobx";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { WarehousMapDataTableProvider } from "./data/warehouse-map-data-table-provider";
import { IWarehouseMapFilterParams } from "../utils/warehouse-map-utils";
import WarehouseMapbaseTableViewStore from "wes_shell_app/warehouse-map-base-table-view-store";
import { WarehousMapRackDataProvider } from "./data/warehouse-map-rack-data-provider";
import {
  TWarehouseMapDataTableRow,
  TWarehouseMapFilterParams,
  TWarehouseMapRackModel,
} from "wes_shell_app/warehouse-map-utils";
import { WarehouseMapRackRequestProvider } from "./data/warehouse-map-rack-request-provider";
import { WarehouseMapRequestProvider } from "./data/warehouse-map-request-provider";

export class WarehouseMapViewStore extends WarehouseMapbaseTableViewStore<
  TWarehouseMapDataTableRow,
  TWarehouseMapFilterParams,
  TWarehouseMapRackModel,
  WarehousMapDataTableProvider,
  WarehousMapRackDataProvider
> {
  constructor() {
    super(
      new WarehousMapDataTableProvider(),
      new WarehousMapRackDataProvider(),
      new WarehouseMapRequestProvider(),
      new WarehouseMapRackRequestProvider()
    );
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return (
      this.currentUserStore.isLoaded &&
      this.tableProvider.isLoaded &&
      this.racksProvider.isLoaded
    );
  }
}

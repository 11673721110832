import { environment } from "environment";
import { IRackFilterParams } from "features/rack/utils/rack-utils";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class RackTableProvider extends DataTableStore<any, IRackFilterParams> {
    store = new SharedContextStore();
    currentStationId = this.store.appContext.currentStationId;

    get endpointUrl(): string { return `ui/rack/${this.currentStationId}`; }
    protected serviceApiUrl = environment.serviceApi;

    get defaultFilter() {
        return new Map<any, any>([
            ['active', 'true'],
        ]);
    }
}
import { Drawer } from "wes_shell_app/drawer";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import RackShelvesTable from "./rack-shelves-table";
import RackShelvesHeader from "./rack-shelves-header";
import RackVisualization from "./rack-visualization";
import Title from "wes_shell_app/title";
import { getTranslation } from "wes_shell_app/localization-utils";

const RackShelvDrawer = observer(() => {
  const store = useShelvesStore();
  return (
    <Drawer
      anchor="right"
      onClose={store.closeDrawer}
      open={store.isDrawerOpen}
      sx={{ width: "70%" }}
    >
      <Title>{`${getTranslation("rack")}: ${store.rack?.name}`}</Title>
      <Box display={"flex"} flexGrow={"initial"} width={"100%"} height={"100%"}>
        <RackVisualization />
        <Box
          width={"60%"}
          borderLeft={"1px solid #dfdfdf"}
          paddingLeft={"1rem"}
        >
          <RackShelvesHeader />
          <Box className={"overflow-hidden mt-4"}>
            <RackShelvesTable />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
});

export default RackShelvDrawer;

import { IClientOrdersModel } from "models/client-models";
import { IOrdersModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable, observable, action } from "mobx";
import { environment } from "environment";
import { OrdersDataTableProvider } from "features/orders/stores/orders-data-table-provider";
import { IOrdersFilterParams } from "../utils/filter-utils";

export class OrdersViewStore extends BaseTableViewStore<
  IOrdersModel,
  IClientOrdersModel,
  IOrdersFilterParams,
  OrdersDataTableProvider
> {
  constructor() {
    super(new OrdersDataTableProvider());
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get stationId() {
    return this.currentUserStore.currentStationId;
  }
}

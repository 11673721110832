declare global {
  interface Window {
    _env_: any;
  }
}

const environmentVariables = window._env_;

export const environment = {
  serviceApi: environmentVariables.REACT_APP_VOICE_PICKING_SERVICE_API,
};
